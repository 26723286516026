@import "src/assets/variables";

.logo {
  img {
    height: 3rem;
  }

  @media (min-width: $medium-size) {
    img {
      height: 4rem;
      padding: 1rem;
    }
  }
}