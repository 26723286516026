@import "src/assets/variables";

.section-tertiary-feature {
  .feature {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: $primary-background-color;
    width: 100vw;
    height: 12rem;
    margin: .5rem 0;
    h3, h4 {
      margin: .5rem;
    }

    h4 {
      font-family: $sans-serif-font;
      a {
        color: $primary-background-color;
      }
    }
  }
  @media(min-width: $large-size) {
    display: flex;
    align-items: center;
    justify-content: center;
    .feature {
      width: 33.33vw;
      height: 16rem;
      margin: 0;
    }
  }

  .feature--first {
    background-image: url("./feature-background1.png");
    background-size: cover;
  }

  .feature--second {
    background-image: url("./feature-background2.png");
    background-size: cover;
  }

  .feature--third {
    background-image: url("./feature-background3.png");
    background-size: cover;
  }
}