@import "src/assets/variables";

.section-apartment-secondary-feature {
  .feature-body {
    .feature {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      color: $primary-background-color;
      width: 100vw;
      height: 12rem;
      margin: .5rem 0;

      h3, h4 {
        margin: .5rem;
      }

      h4 {
        font-family: $main-font;
        font-weight: normal;

        a {
          color: $primary-background-color;
        }
      }
    }

    @media(min-width: $large-size) {
      display: flex;
      align-items: center;
      justify-content: center;
      .feature {
        width: 25vw;
        height: 14rem;
        margin: 0;
        padding: 0;
      }
    }

    .feature--first {
      background-image: url("./apartment-feature-background1.png");
      background-size: cover;
    }

    .feature--second {
      background-image: url("./apartment-feature-background2.png");
      background-size: cover;
    }

    .feature--third {
      background-image: url("./apartment-feature-background1.png");
      background-size: cover;
    }

    .feature--fourth {
      background-image: url("./apartment-feature-background4.png");
      background-size: cover;
    }
  }

  .div-two-column {
    margin: 1rem;
    display: flex;
    justify-content: center;

    .first-column, .second-column {
      margin: 1rem;
      line-height: 3rem;
    }

    .first-column {
      font-family: $serif-font;
      text-align: right;
      font-size: 1.2rem;
    }
    .second-column {
      text-transform: uppercase;
      font-size: 1.1rem;
      text-decoration: underline;
    }
  }

}