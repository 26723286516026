@import "src/assets/variables";

.section-apartments-feature {
  background: $secondary-background-color;
  padding: 0;
  margin: 0;

  .two-columns {
    @media(min-width: $medium-size) {
      display: flex;
    }
  }


  .column-left {
    padding: 2rem 3rem;

    h3 {
      text-align: left;
    }

    p {
      font-family: $sans-serif-font;
      font-size: .7rem;
      font-weight: normal;
      line-height: 1.2rem;
      margin-bottom: 2rem;
    }
  }

  .column-right {

  }

  .one-column {
    .column-left {
      h3 {
        text-align: center;
      }

      text-align: center;

    }
  }
}