@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:400,700|Alegreya+Sans+SC:400,700|IM+Fell+Great+Primer:400,400i&display=swap');
@import "~@renaissancerentals/renaissance-component/lib/assets/App";

.emphasized {
  font-family: $italic-font;
  text-transform: capitalize;
}

main {
  h2 {
    text-transform: capitalize;
  }

  h3 {
    font-family: $sans-serif-font;
    font-size: 1rem;
  }

  p, li {
    font-size: 1.2rem;
  }

  .section-feature {
    text-align: center;
  }

  .slider-header {
    p {
      font-size: .9rem;
    }
  }

  .muncher-dropdown--content {
    .muncher-button {
      background: $primary-background-color;
    }

    .muncher-button--active {
      background: $tertiary-background-color;
    }
  }

  .section-feature {
    @media(min-width: $large-size) {
      display: flex;
      align-items: center;
      padding: 2rem;
      column-count: 3;
      .feature {
        margin: 2rem;

        p {
          text-align: justify;
        }

        #feature1 {

        }
      }
    }
  }

  .section-property-location {
    h3 {
      font-family: $serif-font;
      font-size: 1.3rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    p {
      font-size: 1rem;
      line-height: 1.2rem;

      i {
        font-family: $serif-font;
        font-weight: normal;
        font-size: .8rem;
      }
    }

    a {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
      font-size: .9rem;
    }

  }

  .muncher-badge {
    background-color: $secondary-background-color;

    p {
      color: $primary-color;
    }

    .muncher-button {
      p {
        color: $primary-background-color;
      }
    }

    .selected {
      color: $primary-color;
    }
  }

  .section-card {
    color: $primary-color;

    .card-header {
      background-color: $primary-color;
    }

    .muncher-button--tertiary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }

    i {
      font-family: $serif-font;
    }
  }

  .floorplan--featured {
    .muncher-button {
      background-color: $primary-background-color;
    }
  }

  .floorplan-column-right {
    .muncher-button--tertiary {
      //background-color: $secondary-color;
    }

    .muncher-button--primary {
      background-color: $primary-color;
    }
  }

  .muncher-dropdown--content {
    .muncher-button--secondary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }
  }
  .section-card .card-body--featured{
    background: $primary-color;
  }
}
.hamburger {
  cursor: pointer;
  padding: .5rem;
  color: $secondary-color;
}
