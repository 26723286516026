@import "src/assets/variables";

.section-apartments-hero {
  padding: 0;
  margin: 0;
  text-align: center;


  .div-hero-image {
    background: url(./hero-mobile.png) bottom/cover;
    padding: 0;
    margin: 0;
    height: 35vw;
    color: $secondary-background-color;
    @media (min-width: $medium-size) {
      background: url(./hero.png) bottom/cover;
    }
  }

}

