$primary-color: #4B3D2A !default;
$secondary-color: #4B3D2A !default;
$primary-background-color: white !default;
$secondary-background-color: #ebebe5 !default;
$tertiary-background-color: #D9D9D6 !default;

$main-font: 'Alegreya Sans', sans-serif !default;
$heading-font: 'IM Fell Great Primer', serif !default;
$italic-font: 'IM Fell Great Primer', serif !default;
$nav-font: $main-font !default;
$button-font: $main-font !default;
$button-background-color: $secondary-background-color !default;

$card-slider-background: $primary-color !default;
$search-unit-header-background-color: $secondary-background-color !default;
$floorplan-featured-color: $primary-color !default;
$floorplan-hover-color: $primary-background-color !default;
$floorplan-hero-featured-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
